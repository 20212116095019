import React from "react";
import PontosReducerProvider from "../reducers/pontosReducerProvider";
import Linhas from "../components/linha";

export default function PaginaLinhas() {
  return (
    <PontosReducerProvider>
      <Linhas />
    </PontosReducerProvider>
  );
}
