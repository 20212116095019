import { FirestoreDataConverter } from "firebase/firestore";
import toFirestore from "../models/toFirestore";
import Residencial from "../models/residencial";
import BaseDb from "./baseDb";

export default class ResidencialDb extends BaseDb<
  Residencial,
  {
    residencialId: string;
  }
> {
  _path: string = "residenciais/residencialId";
  getConverter(): FirestoreDataConverter<Residencial> {
    return {
      toFirestore: toFirestore,
      fromFirestore: Residencial.fromFirestore,
    };
  }
}
