import { FirestoreDataConverter } from "firebase/firestore";
import toFirestore from "../models/toFirestore";
import BaseDb from "./baseDb";
import Termo from "../models/termo";

export default class TermoDb extends BaseDb<
  Termo,
  {
    termoId: string;
  }
> {
  _path: string = "termos/termoId";
  getConverter(): FirestoreDataConverter<Termo> {
    return {
      toFirestore: toFirestore,
      fromFirestore: Termo.fromFirestore,
    };
  }
}
