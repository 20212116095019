import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Alert,
} from "@mui/material";
import Passageiro from "../../models/passageiro";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useState } from "react";

export default function DialogExcluirUsuario({
  open,
  onClose,
  usuario,
}: {
  open: boolean;
  onClose: (excluido: boolean) => void;
  usuario: Passageiro;
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>Confirmar Exclusão?</DialogTitle>
      <DialogContent>
        {!!error && <Alert color="error">{error}</Alert>}
        <DialogContentText>
          Tem certeza de que deseja excluir o usuário{" "}
          <strong>{usuario.nome}</strong>? Essa ação não pode ser desfeita.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} variant="text" color="inherit">
          Cancelar
        </Button>
        <Button
          onClick={async () => {
            setLoading(true);
            await httpsCallable<{ uid: string }, void>(
              getFunctions(),
              "usuario-excluirUsuarioAuth"
            )({ uid: usuario.ref.id })
              .then(() => {
                onClose(true);
              })
              .catch((error) => {
                setError(
                  `Não foi possível excluir o usuário. Erro: ${error.message}`
                );
              })
              .finally(() => {
                setLoading(false);
              });
          }}
          color="error"
          variant="contained"
          disabled={loading}
        >
          {loading ? "Excluindo..." : "Sim, excluir"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
