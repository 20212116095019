import { LocationOn, Map, Route, WatchLater } from "@mui/icons-material";
import { Box, Button, Grid, Paper, Typography, alpha } from "@mui/material";
import { Container } from "@mui/system";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <main>
      <Box display="flex" flexDirection="column">
        <Box bgcolor="primary.main" py={4}>
          <Container maxWidth="lg">
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              justifyContent="space-between"
            >
              <img
                alt="logo"
                src="/logo-caribbeanCurrent.png"
                width={64}
                height={64}
              />
              <Button
                size="large"
                variant="outlined"
                color="secondary"
                component={Link}
                to="/login"
                sx={{ fontSize: 20 }}
              >
                Ir para o console
              </Button>
            </Box>
            <Box mt={5} />
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              justifyContent="center"
              alignItems="center"
            >
              <Box display="flex" flexDirection="column" width={600}>
                <Typography
                  variant="h1"
                  fontWeight={1000}
                  fontSize={64}
                  color="#FFFFFF"
                >
                  AG Transporte
                </Typography>
                <Typography
                  variant="h2"
                  fontWeight={600}
                  fontSize={40}
                  color={alpha("#FFFFFF", 0.8)}
                >
                  O companheiro de viagem dos moradores de Alphaville MG
                </Typography>
                <Box mt={15} />
                <Box display="flex" flexDirection="row">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.pengwin.agtransporte"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="Baixar na Google Play"
                      src="/google-play-badge.png"
                      height={64}
                    />
                  </a>
                  <Box mr={4} />
                  <a
                    href="https://apps.apple.com/br/app/ag-transporte/id6446705618"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt="Baixar na App Store"
                      src="/app-store-badge.png"
                      height={64}
                    />
                  </a>
                </Box>
              </Box>
              <Box mr={15} />
              <img alt="Tela App" src="/tela_app.png" />
            </Box>
          </Container>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          bgcolor="secondary.main"
          py={15}
          px={25}
        >
          <Typography
            variant="h2"
            fontWeight={300}
            fontSize={32}
            color={alpha("#FFFFFF", 0.8)}
            align="center"
          >
            O{" "}
            <Typography component="span" fontWeight={1000} fontSize={36}>
              AG Transporte
            </Typography>{" "}
            é a solução perfeita para simplificar seus deslocamentos entre o
            condomínio Alphaville e Belo Horizonte. Tenha acesso fácil a
            horários atualizados, rotas eficientes, pontos estratégicos e
            acompanhe o transporte executivo em tempo real.
          </Typography>
          <Box mt={4} />
          <Typography
            variant="h3"
            fontWeight={600}
            fontSize={40}
            color={alpha("#FFFFFF", 1)}
            align="center"
          >
            Baixe agora e experimente a praticidade!
          </Typography>
        </Box>
        <Box bgcolor="background.default" py={15} px={25}>
          <Grid container spacing={5} justifyContent="stretch">
            <Grid item xs={6}>
              <Paper sx={{ height: "100%" }}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="baseline"
                  p={3}
                  height="100%"
                >
                  <Map fontSize="large" />
                  <Box display="flex" flexDirection="column" pl={2}>
                    <Typography
                      fontSize={40}
                      fontWeight={600}
                      color="textPrimary"
                    >
                      Tempo Real
                    </Typography>
                    <Typography
                      fontSize={32}
                      fontWeight={500}
                      color="textSecondary"
                    >
                      Acompanhe o transporte em tempo real e ganhe tempo
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper sx={{ height: "100%" }}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="baseline"
                  p={3}
                  height="100%"
                >
                  <WatchLater fontSize="large" />
                  <Box display="flex" flexDirection="column" pl={2}>
                    <Typography
                      fontSize={40}
                      fontWeight={600}
                      color="textPrimary"
                    >
                      Horários
                    </Typography>
                    <Typography
                      fontSize={32}
                      fontWeight={500}
                      color="textSecondary"
                    >
                      Escolha o melhor horário consultando todos os horários
                      atualizados
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper sx={{ height: "100%" }}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="baseline"
                  p={3}
                  height="100%"
                >
                  <Route fontSize="large" />
                  <Box display="flex" flexDirection="column" pl={2}>
                    <Typography
                      fontSize={40}
                      fontWeight={600}
                      color="textPrimary"
                    >
                      Rotas
                    </Typography>
                    <Typography
                      fontSize={32}
                      fontWeight={500}
                      color="textSecondary"
                    >
                      Veja no mapa a rota de qualquer uma das linhas
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper sx={{ height: "100%" }}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="baseline"
                  p={3}
                  height="100%"
                >
                  <LocationOn fontSize="large" />
                  <Box display="flex" flexDirection="column" pl={2}>
                    <Typography
                      fontSize={40}
                      fontWeight={600}
                      color="textPrimary"
                    >
                      Pontos
                    </Typography>
                    <Typography
                      fontSize={32}
                      fontWeight={500}
                      color="textSecondary"
                    >
                      Saiba qual o ponto mais próximo e tenha mais segurança
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </main>
  );
}
