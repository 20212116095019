import { FirestoreDataConverter } from "firebase/firestore";
import Linha from "../models/linha";
import toFirestore from "../models/toFirestore";
import BaseDb from "./baseDb";

export default class LinhaDb extends BaseDb<Linha, { linhaId: string }> {
  _path: string = "linhas/linhaId";
  getConverter(): FirestoreDataConverter<Linha> {
    return {
      fromFirestore: Linha.fromFirestore,
      toFirestore: toFirestore,
    };
  }
}
