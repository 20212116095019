import {
  DocumentReference,
  DocumentSnapshot,
  Timestamp,
} from "firebase/firestore";
import MotoristaDb from "../db/motoristaDb";
import Motorista from "./motorista";
import Carro from "./carro";
import Linha from "./linha";
import CorridaDb from "../db/corridaDb";
import CarroDb from "../db/carroDb";
import LinhaDb from "../db/linhaDb";
import LocalizacaoDb from "../db/localizacaoDb";
import Localizacao from "./localizacao";

export default class Corrida implements ICorrida {
  public ref!: DocumentReference<Corrida>;
  public motorista!: DocumentReference<Motorista>;
  public carro!: DocumentReference<Carro>;
  public linha!: DocumentReference<Linha>;
  public dataInicio!: Date;
  public dataFim?: Date;
  public partida?: DocumentReference<Localizacao>;
  public chegada?: DocumentReference<Localizacao>;

  static fromFirestore(snapshot: DocumentSnapshot): Corrida {
    return new Corrida({
      ref: snapshot.ref.withConverter(new CorridaDb().getConverter()),
      motorista: (snapshot.get("motorista") as DocumentReference).withConverter(
        new MotoristaDb().getConverter()
      ),
      carro: (snapshot.get("carro") as DocumentReference).withConverter(
        new CarroDb().getConverter()
      ),
      linha: (snapshot.get("linha") as DocumentReference).withConverter(
        new LinhaDb().getConverter()
      ),
      dataInicio: (snapshot.get("dataInicio") as Timestamp).toDate(),
      dataFim: (snapshot.get("dataFim") as Timestamp | undefined)?.toDate(),
      partida: !!snapshot.get("partida")
        ? (snapshot.get("partida") as DocumentReference).withConverter(
            new LocalizacaoDb().getConverter()
          )
        : undefined,
      chegada: !!snapshot.get("partida")
        ? (snapshot.get("chegada") as DocumentReference).withConverter(
            new LocalizacaoDb().getConverter()
          )
        : undefined,
    });
  }

  constructor(param: ICorrida) {
    Object.assign(this, param);
  }
}

interface ICorrida {
  ref: DocumentReference<Corrida>;
  motorista: DocumentReference<Motorista>;
  carro: DocumentReference<Carro>;
  linha: DocumentReference<Linha>;
  dataInicio: Date;
  dataFim?: Date;
  partida?: DocumentReference<Localizacao>;
  chegada?: DocumentReference<Localizacao>;
}
