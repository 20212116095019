import { FirestoreDataConverter } from "firebase/firestore";
import Carro from "../models/carro";
import toFirestore from "../models/toFirestore";
import BaseDb from "./baseDb";

export default class CarroDb extends BaseDb<
  Carro,
  {
    carroId: string;
  }
> {
  _path: string = "carros/carroId";
  getConverter(): FirestoreDataConverter<Carro> {
    return {
      toFirestore: toFirestore,
      fromFirestore: Carro.fromFirestore,
    };
  }
}
