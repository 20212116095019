import { Box, Container, Paper, useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { getDoc, getDocs } from "firebase/firestore";
import { groupBy, map } from "lodash";
import { useEffect, useState } from "react";
import CheckinDb from "../db/checkinDb";
import Checkin from "../models/checkin";

type BarData = {
  nomeResidencial: string;
  quantidade: number;
};

export default function PaginaCheckins() {
  const [checkins, setCheckins] = useState<Checkin[]>([]);
  const [data, setData] = useState<BarData[]>([]);
  const theme = useTheme();

  useEffect(() => {
    getDocs(new CheckinDb().collection({ checkinId: "" })).then((queryDocs) =>
      setCheckins(map(queryDocs.docs, (doc) => doc.data()))
    );
  }, []);

  useEffect(() => {
    Promise.all(
      map(checkins, async (checkin) => {
        const usuario = await getDoc(checkin.usuario).then((doc) => doc.data());
        const carro = await getDoc(checkin.carro).then((doc) => doc.data()!);
        const residencial = !!usuario
          ? await getDoc(usuario.residencial).then((doc) => doc.data())
          : undefined;
        return {
          checkin,
          usuario,
          carro,
          residencial,
        };
      })
    )
      .then((response) =>
        groupBy(
          response,
          (checkin) => checkin.residencial?.nome || "não definido"
        )
      )
      .then((response) =>
        map(response, (checkinList, nomeResidencial) => ({
          nomeResidencial,
          quantidade: checkinList.length,
        }))
      )
      .then((dt) => setData(dt));
  }, [checkins]);

  return (
    <Container maxWidth="lg">
      <Paper>
        <Box height={400}>
          <ResponsiveBar
            theme={{
              fontFamily: "Mulish",
              axis: {
                ticks: {
                  text: {
                    fontWeight: 300,
                    color: theme.palette.text.secondary,
                  },
                },
                legend: {
                  text: {
                    fontWeight: 300,
                    color: theme.palette.text.secondary,
                  },
                },
              },
              labels: {
                text: { fontWeight: 700 },
              },
            }}
            margin={{ top: 20, right: 40, bottom: 40, left: 40 }}
            padding={0.2}
            borderRadius={12}
            borderWidth={0}
            colors={{ scheme: "accent" }}
            keys={["quantidade"]}
            data={data}
            indexBy="nomeResidencial"
            colorBy="indexValue"
          />
        </Box>
      </Paper>
    </Container>
  );
}
