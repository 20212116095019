import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  FormGroup,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { deleteDoc, onSnapshot, query, setDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { map } from "lodash";
import { useEffect, useState } from "react";
import avatarColorGenerator from "../util/avatarColorGenerator";
import { Add, Delete, Edit } from "@mui/icons-material";
import Administrador from "../models/administrador";
import AdministradorDb from "../db/administradorDb";
import { LoadingButton } from "@mui/lab";

export default function PaginaAdministradores() {
  const [administradores, setAdministradores] = useState<Administrador[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    return onSnapshot(
      query(
        new AdministradorDb().collection({
          administradorId: "",
        })
      ),
      (queryDocs) => {
        setAdministradores(map(queryDocs.docs, (doc) => doc.data()));
      }
    );
  }, []);

  return (
    <Container maxWidth="lg">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Administrador</TableCell>
              <TableCell>Email</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(administradores, (administrador) => {
              return <RowAdministrador administrador={administrador} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Fab
        variant="extended"
        size="large"
        color="success"
        sx={{ position: "fixed", bottom: 32, right: 32 }}
        onClick={() => setOpen(true)}
      >
        <Add />
        Adicionar
      </Fab>
      <DialogAdministrador open={open} onClose={() => setOpen(false)} />
    </Container>
  );
}

function RowAdministrador({ administrador }: { administrador: Administrador }) {
  const [open, setOpen] = useState(false);

  return (
    <TableRow key={administrador.ref.id}>
      <TableCell>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Avatar
            sx={{ backgroundColor: avatarColorGenerator(administrador.ref.id) }}
          >
            {administrador.nome[0]}
          </Avatar>
          <Box mr={1} />
          {administrador.nome}
        </Box>
      </TableCell>
      <TableCell>{administrador.email}</TableCell>
      <TableCell align="right">
        <DialogAdministrador
          administrador={administrador}
          open={open}
          onClose={() => setOpen(false)}
        />
        <IconButton color="info" onClick={() => setOpen(true)}>
          <Edit />
        </IconButton>
        <IconButton
          color="error"
          disabled
          onClick={() => deleteDoc(administrador.ref)}
        >
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function DialogAdministrador({
  open,
  onClose,
  administrador,
}: {
  open: boolean;
  onClose: () => void;
  administrador?: Administrador;
}) {
  const [aguarde, setAguarde] = useState(false);
  const [nome, setNome] = useState(administrador?.nome ?? "");
  const [email, setEmail] = useState(administrador?.email ?? "");

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <FormGroup>
          <Box display="flex" flexDirection="column">
            <TextField
              label="Nome"
              autoComplete="name"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
            <Box pb={1} />
            <TextField
              label="Email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          size="medium"
          variant="text"
          color={"inherit"}
          onClick={onClose}
        >
          {"Cancelar"}
        </Button>
        {!!administrador ? (
          <LoadingButton
            size="medium"
            variant="contained"
            color={"success"}
            disabled={!!!nome}
            loading={aguarde}
            onClick={() => {
              setAguarde(true);
              setDoc(
                administrador.ref,
                new Administrador({
                  ...administrador,
                  nome,
                  email,
                })
              )
                .then(onClose)
                .finally(() => setAguarde(false));
            }}
          >
            {"Salvar"}
          </LoadingButton>
        ) : (
          <LoadingButton
            size="medium"
            variant="contained"
            color={"success"}
            disabled={!!!nome || !!!email}
            loading={aguarde}
            onClick={() => {
              setAguarde(true);
              httpsCallable<
                {
                  nome: string;
                  email: string;
                },
                void
              >(
                getFunctions(),
                "usuario-criarAdministrador"
              )({
                nome,
                email,
              })
                .then(onClose)
                .finally(() => setAguarde(false));
            }}
          >
            {"Adicionar"}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
