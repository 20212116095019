import {
  DirectionsBusFilled,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import { deleteDoc, GeoPoint, setDoc } from "firebase/firestore";
import { map, pick, reverse } from "lodash";
import React, { useState } from "react";
import Linha from "../../models/linha";
import { usePontos } from "../../reducers/pontosReducerProvider";
import ItemNovoPonto from "./itemNovoPonto";
import ItemPonto from "./itemPonto";

type Props = {
  linha: Linha;
  rota: GeoPoint[];
  expandido: boolean;
  onClick: (linha: Linha) => void;
};

export default function ItemLinha({ linha, rota, expandido, onClick }: Props) {
  const [nomeLinha, setNomeLinha] = useState(linha.nome);
  const { pontos } = usePontos();

  return (
    <React.Fragment>
      <ListItemButton onClick={() => onClick(linha)}>
        <ListItemIcon>
          <DirectionsBusFilled />
        </ListItemIcon>
        <ListItemText primary={linha.nome} />
        {expandido ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse key={linha.nome + "-collapse"} in={expandido}>
        <List>
          <ListItem key="nome-linha">
            <TextField
              fullWidth
              label="Nome da Linha"
              size="small"
              variant="outlined"
              value={nomeLinha}
              onChange={(e) => setNomeLinha(e.target.value)}
            />
          </ListItem>
          <ItemNovoPonto />
          {reverse(
            map(pontos, (ponto, index) => (
              <ItemPonto ponto={ponto} key={index} />
            ))
          )}
          <ListItem
            key="salvar-linha"
            divider
            style={{ justifyContent: "flex-end" }}
          >
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={() => {
                deleteDoc(linha.ref);
              }}
            >
              Apagar
            </Button>
            <Box pr={1} />
            <Button
              size="small"
              variant="contained"
              color="success"
              onClick={() => {
                setDoc(linha.ref, {
                  ...linha,
                  nome: nomeLinha,
                  pontos: map(pontos, (p) =>
                    pick(p, ["id", "nome", "localizacao", "foto"])
                  ),
                  rota: rota,
                });
              }}
            >
              Salvar
            </Button>
          </ListItem>
        </List>
      </Collapse>
    </React.Fragment>
  );
}
