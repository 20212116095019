import { Add, Delete, Download, Edit, QrCode } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  addDoc,
  deleteDoc,
  onSnapshot,
  query,
  setDoc,
} from "firebase/firestore";
import { map } from "lodash";
import { QRCodeCanvas } from "qrcode.react";
import { useEffect, useState } from "react";
import CarroDb from "../db/carroDb";
import Carro from "../models/carro";

export default function PaginaCarros() {
  const [carros, setCarros] = useState<Carro[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    return onSnapshot(
      query(
        new CarroDb().collection({
          carroId: "",
        })
      ),
      (queryDocs) => {
        setCarros(map(queryDocs.docs, (doc) => doc.data()));
      }
    );
  }, []);

  return (
    <Container maxWidth="lg">
      <DialogCarro open={open} onClose={() => setOpen(false)} />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Número</TableCell>
              <TableCell>Placa</TableCell>
              <TableCell>Micro-Ônibus</TableCell>
              <TableCell>QRCode</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(carros, (carro) => {
              return <RowCarro key={carro.ref.id} carro={carro} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Fab
        variant="extended"
        size="large"
        color="success"
        sx={{ position: "fixed", bottom: 32, right: 32 }}
        onClick={() => setOpen(true)}
      >
        <Add />
        Adicionar
      </Fab>
    </Container>
  );
}

function RowCarro({ carro }: { carro: Carro }) {
  const [openQrCode, setOpenQrCode] = useState(false);
  const [open, setOpen] = useState(false);
  const downloadQR = () => {
    const canvas = document.getElementById(carro.ref.id) as HTMLCanvasElement;
    if (!!canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = `${carro.placa}_QRCode.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <TableRow key={carro.ref.id}>
      <TableCell>{carro.numero}</TableCell>
      <TableCell>{carro.placa}</TableCell>
      <TableCell>{carro.microOnibus ? "Sim" : ""}</TableCell>
      <TableCell>
        <Button
          variant="text"
          startIcon={<QrCode />}
          onClick={() => setOpenQrCode(true)}
        >
          Gerar QR Code
        </Button>
        <Dialog open={openQrCode} onClose={() => setOpenQrCode(false)}>
          <DialogContent>
            <QRCodeCanvas
              id={carro.ref.id}
              value={`https://agtransporte.web.app/checkin?carro=${carro.ref.id}`}
              includeMargin={true}
              size={512}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Download />}
              onClick={downloadQR}
            >
              Baixar
            </Button>
          </DialogActions>
        </Dialog>
      </TableCell>
      <TableCell align="right">
        <DialogCarro carro={carro} open={open} onClose={() => setOpen(false)} />
        <IconButton color="info" onClick={() => setOpen(true)}>
          <Edit />
        </IconButton>
        <IconButton color="error" onClick={() => deleteDoc(carro.ref)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function DialogCarro({
  open,
  onClose,
  carro,
}: {
  open: boolean;
  onClose: () => void;
  carro?: Carro;
}) {
  const [numero, setNumero] = useState(carro?.numero ?? "");
  const [placa, setPlaca] = useState(carro?.placa ?? "");
  const [microOnibus, setMicroOnibus] = useState(carro?.microOnibus ?? false);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <FormGroup>
          <Box display="flex" flexDirection="column">
            <TextField
              fullWidth
              label="Número"
              value={numero}
              onChange={(e) => setNumero(e.target.value)}
            />
            <Box pt={1} />
            <TextField
              fullWidth
              label="Placa"
              value={placa}
              onChange={(e) => setPlaca(e.target.value)}
            />
            <Box pt={1} />
            <FormControlLabel
              control={
                <Switch
                  color="info"
                  checked={microOnibus}
                  onChange={(e) => setMicroOnibus(e.target.checked)}
                />
              }
              label="Micro-ônibus"
            />
          </Box>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          size="medium"
          variant="text"
          color={"inherit"}
          onClick={onClose}
        >
          {"Cancelar"}
        </Button>
        {!!carro ? (
          <Button
            size="medium"
            variant="contained"
            color={"success"}
            disabled={!!!numero || !!!placa}
            onClick={() =>
              setDoc(
                carro.ref,
                new Carro({
                  ...carro,
                  numero,
                  placa,
                  microOnibus,
                })
              ).then(onClose)
            }
          >
            {"Salvar"}
          </Button>
        ) : (
          <Button
            size="medium"
            variant="contained"
            color={"success"}
            disabled={!!!numero || !!!placa}
            onClick={() =>
              addDoc(
                new CarroDb().collection({ carroId: "" }),
                new Carro({
                  ref: new CarroDb().doc({ carroId: "-" }),
                  numero,
                  placa,
                  microOnibus,
                })
              ).then(onClose)
            }
          >
            {"Adicionar"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
