import { FirestoreDataConverter } from "firebase/firestore";
import toFirestore from "../models/toFirestore";
import BaseDb from "./baseDb";
import Alerta from "../models/alerta";

export default class AlertaDb extends BaseDb<
  Alerta,
  {
    alertaId: string;
  }
> {
  _path: string = "alertas/alertaId";
  getConverter(): FirestoreDataConverter<Alerta> {
    return {
      toFirestore: toFirestore,
      fromFirestore: Alerta.fromFirestore,
    };
  }
}
