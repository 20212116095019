import { Add, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import {
  DocumentReference,
  addDoc,
  deleteDoc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
} from "firebase/firestore";
import { map, without } from "lodash";
import { useEffect, useState } from "react";
import Horario, { DIAS } from "../models/horario";
import HorarioDb from "../db/horarioDb";
import Linha from "../models/linha";
import LinhaSelect from "../components/linha/linhaSelect";
import ChipDia from "../util/chipDia";

export default function PaginaHorarios() {
  const [horarios, setHorarios] = useState<Horario[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    return onSnapshot(
      query(
        new HorarioDb().collection({
          horarioId: "",
        })
      ),
      (queryDocs) => {
        setHorarios(map(queryDocs.docs, (doc) => doc.data()));
      }
    );
  }, []);

  return (
    <Container maxWidth="lg">
      <DialogHorario open={open} onClose={() => setOpen(false)} />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Linha</TableCell>
              <TableCell>Dias</TableCell>
              <TableCell>Horarios</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(horarios, (horario) => {
              return <RowHorario key={horario.ref.id} horario={horario} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Fab
        variant="extended"
        size="large"
        color="success"
        sx={{ position: "fixed", bottom: 32, right: 32 }}
        onClick={() => setOpen(true)}
      >
        <Add />
        Adicionar
      </Fab>
    </Container>
  );
}

function RowHorario({ horario }: { horario: Horario }) {
  const [open, setOpen] = useState(false);
  const [linha, setLinha] = useState<Linha>();

  useEffect(() => {
    getDoc(horario.linha).then((linhaDoc) => setLinha(linhaDoc.data()));
  }, [horario.linha]);

  return (
    <TableRow key={horario.ref.id}>
      <TableCell>{linha?.nome}</TableCell>
      <TableCell>{horario.ativo ? "Ativo" : "Inativo"}</TableCell>
      <TableCell>
        <ChipDia dia={horario.dias} />
      </TableCell>
      <TableCell align="right">
        <DialogHorario
          horario={horario}
          open={open}
          onClose={() => setOpen(false)}
        />
        <IconButton color="info" onClick={() => setOpen(true)}>
          <Edit />
        </IconButton>
        <IconButton color="error" onClick={() => deleteDoc(horario.ref)}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function DialogHorario({
  open,
  onClose,
  horario,
}: {
  open: boolean;
  onClose: () => void;
  horario?: Horario;
}) {
  const [linha, setLinha] = useState<DocumentReference<Linha> | undefined>(
    horario?.linha ?? undefined
  );
  const [ativo, setAtivo] = useState<boolean>(horario?.ativo ?? true);
  const [dias, setDias] = useState<DIAS | undefined>(horario?.dias);
  const [hora, setHora] = useState("00:00");
  const [horarios, setHorarios] = useState<string[]>(horario?.horarios ?? []);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <FormGroup>
          <Box display="flex" flexDirection="column">
            <LinhaSelect value={linha} onSelect={(linha) => setLinha(linha)} />
            <Box pt={1} />
            <FormControlLabel
              labelPlacement="end"
              control={
                <Switch
                  color="info"
                  checked={ativo}
                  onChange={(e) => setAtivo(e.target.checked)}
                />
              }
              label="Ativo"
            />
          </Box>
        </FormGroup>
        <Box pt={1} />
        <FormControl>
          <FormLabel id="dias-radio-label">Dias</FormLabel>
          <RadioGroup
            row
            aria-labelledby="dias-radio-label"
            name="dias-radio"
            onChange={(e) => setDias(e.target.value as DIAS)}
          >
            {map([DIAS.Util, DIAS.Sabado, DIAS.DomingoEFeriado], (dia) => (
              <FormControlLabel
                value={dia}
                control={<Radio />}
                label={<ChipDia dia={dia} />}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Box pt={1} />
        {map(horarios, (horario) => (
          <Chip
            variant="outlined"
            color="info"
            label={horario}
            onDelete={() => setHorarios(without(horarios, horario))}
          />
        ))}
        <Box pt={2} />
        <TextField
          fullWidth
          label="Horário"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              adicionarHora();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton color="info" onClick={() => adicionarHora()}>
                  <Add />
                </IconButton>
              </InputAdornment>
            ),
          }}
          type="time"
          value={hora}
          onChange={(e) => setHora(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          size="medium"
          variant="text"
          color={"inherit"}
          onClick={onClose}
        >
          {"Cancelar"}
        </Button>
        {!!horario ? (
          <Button
            size="medium"
            variant="contained"
            color={"success"}
            disabled={!!!linha || !!!dias || horarios.length === 0}
            onClick={() =>
              setDoc(
                horario.ref,
                new Horario({
                  ...horario,
                  linha: linha!,
                  ativo,
                  dias: dias!,
                  horarios,
                })
              ).then(onClose)
            }
          >
            {"Salvar"}
          </Button>
        ) : (
          <Button
            size="medium"
            variant="contained"
            color={"success"}
            disabled={!!!linha || !!!dias || horarios.length === 0}
            onClick={() =>
              addDoc(
                new HorarioDb().collection({ horarioId: "" }),
                new Horario({
                  ref: new HorarioDb().doc({ horarioId: "-" }),
                  linha: linha!,
                  ativo,
                  dias: dias!,
                  horarios,
                })
              ).then(onClose)
            }
          >
            {"Adicionar"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );

  function adicionarHora() {
    setHorarios([...horarios, hora]);
    setHora("00:00");
  }
}
