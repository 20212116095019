import { Done, Radar, Route } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  GeoPoint,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { chain, first, last, map } from "lodash";
import { useEffect, useState } from "react";
import Linha from "../models/linha";
import Corrida from "../models/corrida";
import CorridaDb from "../db/corridaDb";
import Motorista from "../models/motorista";
import Carro from "../models/carro";
import avatarColorGenerator from "../util/avatarColorGenerator";
import moment from "moment";
import { Link } from "react-router-dom";
import Localizacao from "../models/localizacao";

export default function PaginaCorridas() {
  const [corridas, setCorridas] = useState<Corrida[]>([]);

  useEffect(() => {
    return onSnapshot(
      query(
        new CorridaDb().collection({
          corridaId: "",
        }),
        orderBy("dataInicio", "desc"),
        limit(100)
      ),
      (queryDocs) => {
        setCorridas(map(queryDocs.docs, (doc) => doc.data()));
      }
    );
  }, []);

  return (
    <Container maxWidth="xl">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Motorista</TableCell>
              <TableCell>Carro</TableCell>
              <TableCell>Linha</TableCell>
              <TableCell>Partida</TableCell>
              <TableCell>Chegada</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chain(corridas)
              .orderBy("dataInicio", "desc")
              .orderBy((c) => (!c.dataFim ? 0 : 1))
              .map((corrida) => {
                return <RowCorrida key={corrida.ref.id} corrida={corrida} />;
              })
              .value()}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

function RowCorrida({ corrida }: { corrida: Corrida }) {
  const [motorista, setMotorista] = useState<Motorista>();
  const [carro, setCarro] = useState<Carro>();
  const [linha, setLinha] = useState<Linha>();
  const [partida, setPartida] = useState<Localizacao>();
  const [chegada, setChegada] = useState<Localizacao>();

  useEffect(() => {
    getDoc(corrida.motorista).then((motoristaDoc) =>
      setMotorista(motoristaDoc.data())
    );
  }, [corrida.motorista]);

  useEffect(() => {
    getDoc(corrida.carro).then((carroDoc) => setCarro(carroDoc.data()));
  }, [corrida.carro]);

  useEffect(() => {
    getDoc(corrida.linha).then((linhaDoc) => setLinha(linhaDoc.data()));
  }, [corrida.linha]);

  useEffect(() => {
    if (!!corrida.partida)
      getDoc(corrida.partida).then((partidaDoc) =>
        setPartida(partidaDoc.data())
      );
  }, [corrida.partida]);

  useEffect(() => {
    if (!!corrida.chegada)
      getDoc(corrida.chegada).then((chegadaDoc) =>
        setChegada(chegadaDoc.data())
      );
  }, [corrida.chegada]);

  const distanciaPartida = calcularDistanciaEmMetros(
    first(linha?.pontos)?.localizacao,
    partida?.posicao
  );
  const distanciaChegada = calcularDistanciaEmMetros(
    last(linha?.pontos)?.localizacao,
    chegada?.posicao
  );
  return (
    <TableRow key={corrida.ref.id}>
      <TableCell>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Avatar
            sx={{
              backgroundColor: avatarColorGenerator(motorista?.ref.id ?? ""),
            }}
          >
            {motorista?.nome[0] ?? ""}
          </Avatar>
          <Box mr={1} />
          {motorista?.nome ?? ""}
        </Box>
      </TableCell>
      <TableCell>{carro?.numero}</TableCell>
      <TableCell>{linha?.nome}</TableCell>
      <TableCell>
        <Box>
          <Typography variant="inherit" gutterBottom>
            {moment(corrida.dataInicio).format("dddd, DD/MM HH:mm:ss")}
          </Typography>
          {!!distanciaPartida ? (
            <Chip
              size="small"
              icon={<Radar />}
              color={(function () {
                if (distanciaPartida < 50) return "success";
                if (distanciaPartida < 100) return "warning";
                else return "error";
              })()}
              label={`${distanciaPartida.toFixed(0)}m`}
            />
          ) : (
            <></>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Box>
          {!!corrida.dataFim ? (
            <Typography variant="inherit" gutterBottom>
              {moment(corrida.dataFim).format("dddd, DD/MM HH:mm:ss")}
            </Typography>
          ) : (
            <Button
              startIcon={<Done />}
              variant="contained"
              color="secondary"
              onClick={() =>
                updateDoc(corrida.ref, { dataFim: moment().toDate() })
              }
            >
              Finalizar Corrida
            </Button>
          )}
          {!!distanciaChegada ? (
            <Chip
              size="small"
              icon={<Radar />}
              color={(function () {
                if (distanciaChegada < 50) return "success";
                if (distanciaChegada < 100) return "warning";
                else return "error";
              })()}
              label={`${distanciaChegada.toFixed(0)}m`}
            />
          ) : (
            <></>
          )}
        </Box>
      </TableCell>
      <TableCell></TableCell>
      <TableCell>
        <IconButton color="info" component={Link} to={corrida.ref.id}>
          <Route />
        </IconButton>{" "}
      </TableCell>
    </TableRow>
  );
}

// Função para calcular a distância entre dois pontos GeoPoint (Firebase) em metros
function calcularDistanciaEmMetros(
  ponto1?: GeoPoint,
  ponto2?: GeoPoint
): number | undefined {
  if (!!!ponto1 || !!!ponto2) return undefined;
  // Obter as latitudes e longitudes dos pontos
  const latitude1 = ponto1.latitude;
  const longitude1 = ponto1.longitude;
  const latitude2 = ponto2.latitude;
  const longitude2 = ponto2.longitude;

  // Converter graus para radianos
  const latRadian1 = (latitude1 * Math.PI) / 180;
  const lonRadian1 = (longitude1 * Math.PI) / 180;
  const latRadian2 = (latitude2 * Math.PI) / 180;
  const lonRadian2 = (longitude2 * Math.PI) / 180;

  // Calcular a diferença de latitudes e longitudes
  const dLat = latRadian2 - latRadian1;
  const dLon = lonRadian2 - lonRadian1;

  // Calcular o raio da Terra (em metros)
  const raioTerra = 6371000;

  // Calcular a distância entre os pontos usando a fórmula de Haversine
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(latRadian1) *
      Math.cos(latRadian2) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distanciaEmMetros = raioTerra * c;

  // Retornar a distância em metros
  return distanciaEmMetros;
}
