import { Container, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { TabContext, TabPanel } from "@mui/lab";
import Passageiros from "../components/passageiros/passageiros";
import Emitidas from "../components/passageiros/emitidas";
import Canceladas from "../components/passageiros/canceladas";
import { InstantSearch } from "react-instantsearch";
import { algoliasearch } from "algoliasearch";

// Configuração do cliente do Algolia
const searchClient = algoliasearch(
  "9UTDK6TPLU", // Substitua pelo seu Application ID
  "282d817fe4e944a3b8295598fc9dfa0b" // Substitua pela sua Search-Only API Key
);

export default function PaginaPassageiros() {
  const [value, setValue] = useState("Passageiros");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="lg">
      <TabContext value={value}>
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          <Tab label="Passageiros" value="Passageiros" />
          <Tab label="Credenciais Emitidas" value="Credenciais Emitidas" />
          <Tab label="Credenciais Canceladas" value="Credenciais Canceladas" />
        </Tabs>
        <TabPanel value="Passageiros">
          <InstantSearch searchClient={searchClient} indexName="passageiros">
            <Passageiros />
          </InstantSearch>
        </TabPanel>
        <TabPanel value="Credenciais Emitidas">
          <Emitidas />
        </TabPanel>
        <TabPanel value="Credenciais Canceladas">
          <Canceladas />
        </TabPanel>
      </TabContext>
    </Container>
  );
}
