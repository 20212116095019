import {
  DocumentReference,
  DocumentSnapshot,
  GeoPoint,
} from "firebase/firestore";
import LinhaDb from "../db/linhaDb";

export default class Linha implements ILinha {
  public ref!: DocumentReference<Linha>;
  public nome!: string;
  public pontos!: Ponto[];
  public rota!: GeoPoint[];
  constructor(param: ILinha) {
    Object.assign(this, param);
  }
  static fromFirestore(snapshot: DocumentSnapshot): Linha {
    return new Linha({
      ref: snapshot.ref.withConverter(new LinhaDb().getConverter()),
      nome: snapshot.get("nome"),
      pontos: snapshot.get("pontos"),
      rota: snapshot.get("rota"),
    });
  }
}

interface ILinha {
  ref: DocumentReference<Linha>;
  nome: string;
  pontos: { nome: string; localizacao: GeoPoint }[];
  rota: GeoPoint[];
}

export interface Ponto {
  id: string;
  nome: string;
  localizacao: GeoPoint;
  foto: string;
}
