import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [usuario, setUsuario] = useState<User>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    return onAuthStateChanged(getAuth(), (userAuth) => {
      if (userAuth) {
        setIsAuthenticated(true);
        setUsuario(userAuth);
      } else {
        setUsuario(undefined);
        setIsAuthenticated(false);
      }
      setLoading(false);
    });
  }, []);

  return useMemo(
    () => (
      <AuthContext.Provider
        value={{
          loading,
          isAuthenticated,
          usuario,
        }}
      >
        {children}
      </AuthContext.Provider>
    ),
    [isAuthenticated, loading, usuario, children]
  );
};

const AuthContext = React.createContext<{
  loading: boolean;
  isAuthenticated: boolean;
  usuario?: User;
}>({
  loading: true,
  isAuthenticated: false,
});

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};
