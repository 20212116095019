import { FirestoreDataConverter } from "firebase/firestore";
import Checkin from "../models/checkin";
import toFirestore from "../models/toFirestore";
import BaseDb from "./baseDb";

export default class CheckinDb extends BaseDb<
  Checkin,
  {
    checkinId: string;
  }
> {
  _path: string = "checkins/checkinId";
  getConverter(): FirestoreDataConverter<Checkin> {
    return {
      toFirestore: toFirestore,
      fromFirestore: Checkin.fromFirestore,
    };
  }
}
