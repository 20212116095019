import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DocumentReference, getDocs } from "firebase/firestore";
import { find, map, orderBy } from "lodash";
import { useEffect, useState } from "react";
import Linha from "../../models/linha";
import LinhaDb from "../../db/linhaDb";

type Props = {
  value?: DocumentReference<Linha>;
  onSelect: (linha?: DocumentReference<Linha>) => void;
};

export default function LinhaSelect({ value, onSelect }: Props) {
  const [linhas, setLinhas] = useState<Linha[]>([]);
  const [linhaSelecionada, setLinhaSelecionada] = useState<
    DocumentReference<Linha> | undefined
  >(value);

  useEffect(() => {
    getDocs(
      new LinhaDb().collection({
        linhaId: "",
      })
    ).then((query) =>
      setLinhas(
        orderBy(
          map(query.docs, (doc) => doc.data()),
          "nome"
        )
      )
    );
  }, []);

  useEffect(() => {
    onSelect(linhaSelecionada);
  }, [onSelect, linhaSelecionada]);

  return (
    <>
      <FormControl>
        <InputLabel id="linha-select-label">Linha</InputLabel>
        <Select
          label="linha"
          id="linha-select"
          labelId="linha-select-label"
          variant="outlined"
          sx={{ minWidth: "24ch" }}
          value={linhaSelecionada?.id}
          onChange={(e) =>
            setLinhaSelecionada(
              find(linhas, (c) => c.ref.id === e.target.value)!.ref
            )
          }
        >
          {map(linhas, (linha) => (
            <MenuItem key={linha.ref.id} value={linha.ref.id}>
              {linha.nome}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
