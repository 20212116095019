import { Logout, Menu } from "@mui/icons-material";
import {
  AppBar,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  styled,
  Toolbar,
} from "@mui/material";
import { Box } from "@mui/system";
import { getAuth } from "firebase/auth";
import { chain } from "lodash";
import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { AppRoutes } from "../App";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Layout() {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar color="primary" position="fixed" component="div">
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={() => setOpenMenu(true)}
            edge="start"
          >
            <Menu />
          </IconButton>
          <Box
            mx={2}
            component="img"
            width={40}
            height={40}
            maxWidth={40}
            maxHeight={40}
            src="/logo-caribbeanCurrent.png"
          />

          <Box flexGrow={1} />
          <IconButton color="inherit" onClick={() => getAuth().signOut()}>
            <Logout />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)}>
        <List>
          {chain(AppRoutes)
            .filter((r) => r.drawer)
            .map((appRoute) => (
              <ListItemButton
                key={appRoute.path}
                component={Link}
                to={`/app/${appRoute.path}`}
                onClick={() => setOpenMenu(false)}
              >
                <ListItemText primary={appRoute.titulo} />
              </ListItemButton>
            ))
            .value()}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        <Box p={4}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
