import { useState, useEffect } from "react";
import {
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import { Container } from "@mui/system";
import Linha from "../../models/linha";
import { addDoc, GeoPoint, onSnapshot } from "firebase/firestore";
import { find, map, max, min, orderBy } from "lodash";
import Map, { geoPointToLatLng, latLngToGeoPoint, PontoMap } from "../map";
import {
  PontosActionType,
  usePontos,
  usePontosDispatch,
} from "../../reducers/pontosReducerProvider";
import LinhaDb from "../../db/linhaDb";
import Direcoes from "../map/direcoes";
import ItemLinha from "./itemLinha";
import { Add, DirectionsBusFilled } from "@mui/icons-material";

export default function Linhas() {
  const [linhas, setLinhas] = useState<Linha[]>();
  const [rota, setRota] = useState<GeoPoint[]>([]);
  const [linhaSelecionada, setLinhaSelecionada] = useState("");
  const [bounds, setBounds] = useState<google.maps.LatLngBoundsLiteral>();
  const { pontos } = usePontos();
  const pontosDispatch = usePontosDispatch();
  const pontosLatLng = map(pontos, (p) => geoPointToLatLng(p.localizacao));
  const [calcularRota, setCalcularRota] = useState(false);

  useEffect(() => {
    return onSnapshot(
      new LinhaDb().collection({
        linhaId: "",
      }),
      (snapshot) =>
        setLinhas(
          orderBy(
            map(snapshot.docs, (doc) => doc.data()),
            "nome"
          )
        )
    );
  }, []);

  useEffect(() => {
    const linha = find(linhas, (l) => l.ref.id === linhaSelecionada);
    pontosDispatch({
      type: PontosActionType.POPULAR,
      payload: {
        popular: map(linha?.pontos, (p) => ({
          ...p,
          destaque: false,
          selecionado: false,
        })),
      },
    });

    setRota(linha?.rota ?? []);

    if (linha && linha.pontos.length > 0) {
      setBounds({
        north: max(map(linha.pontos, (p) => p.localizacao.latitude))!,
        south: min(map(linha.pontos, (p) => p.localizacao.latitude))!,
        east: max(map(linha.pontos, (p) => p.localizacao.longitude))!,
        west: min(map(linha.pontos, (p) => p.localizacao.longitude))!,
      });
    }
  }, [linhas, pontosDispatch, linhaSelecionada]);

  useEffect(() => setCalcularRota(true), [pontos.length]);

  const rotaCallback = (rota: google.maps.LatLng[]): void => {
    setRota(map(rota, latLngToGeoPoint));
    setCalcularRota(false);
  };
  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={8}
          sx={{ height: `calc(100vh - 64px - 24px - 24px)` }}
        >
          <Map
            bounds={bounds}
            pontos={map(
              pontos,
              (p) =>
                ({
                  ...p,
                  onChangeLatLng: (latLng) => {
                    pontosDispatch({
                      type: PontosActionType.EDITAR,
                      payload: {
                        editar: {
                          ...p,
                          localizacao: latLngToGeoPoint(latLng),
                        },
                      },
                    });
                    setCalcularRota(true);
                  },
                  onClick: () =>
                    pontosDispatch({
                      type: PontosActionType.EDITAR,
                      payload: {
                        editar: {
                          ...p,
                          selecionado: true,
                        },
                      },
                    }),
                } as PontoMap)
            )}
            rota={rota}
          >
            <Direcoes
              calcular={calcularRota}
              rotaCallback={rotaCallback}
              pontos={pontosLatLng}
            />
          </Map>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper>
            <List
              sx={{
                "& > div > .MuiListItemIcon-root": {
                  color: "richBlack.main",
                },
                "& > div > .MuiListItemText-root > .MuiListItemText-primary": {
                  fontWeight: "medium",
                  color: "richBlack.main",
                },
                "& > div > .MuiSvgIcon-root": {
                  color: "richBlack.main",
                },
              }}
            >
              {map(linhas, (linha, index) => (
                <ItemLinha
                  linha={linha}
                  rota={rota}
                  expandido={linhaSelecionada === linha.ref.id}
                  onClick={() => {
                    if (linha.ref.id === linhaSelecionada)
                      setLinhaSelecionada("");
                    else setLinhaSelecionada(linha.ref.id);
                  }}
                  key={index}
                />
              ))}
              <ListItemButton
                key="nova-linha"
                onClick={() => {
                  addDoc(
                    new LinhaDb().collection({
                      linhaId: "",
                    }),
                    new Linha({
                      ref: new LinhaDb().doc({
                        linhaId: "-",
                      }),
                      nome: `Linha ${(linhas?.length ?? 0) + 1}`,
                      pontos: [],
                      rota: [],
                    })
                  ).then((value) => setLinhaSelecionada(value.id));
                }}
              >
                <ListItemIcon>
                  <DirectionsBusFilled />
                </ListItemIcon>
                <ListItemText primary="Nova Linha" />
                <Add />
              </ListItemButton>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
