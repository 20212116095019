import { DocumentSnapshot } from "firebase/firestore";
import { GeoPoint } from "firebase/firestore";
import { DocumentReference } from "firebase/firestore";
import CarroDb from "../db/carroDb";
import CheckinDb from "../db/checkinDb";
import PassageiroDb from "../db/passageiroDb";
import Carro from "./carro";
import Passageiro from "./passageiro";

export default class Checkin implements ICheckin {
  public ref!: DocumentReference<Checkin>;
  public localizacao!: GeoPoint;
  public dataHora!: Date;
  public usuario!: DocumentReference<Passageiro>;
  public carro!: DocumentReference<Carro>;
  constructor(param: ICheckin) {
    Object.assign(this, param);
  }

  static fromFirestore(snapshot: DocumentSnapshot) {
    return new Checkin({
      ref: snapshot.ref.withConverter(new CheckinDb().getConverter()),
      localizacao: snapshot.get("localizacao"),
      dataHora: snapshot.get("dataHora").toDate(),
      usuario: (snapshot.get("usuario") as DocumentReference).withConverter(
        new PassageiroDb().getConverter()
      ),
      carro: (snapshot.get("carro") as DocumentReference).withConverter(
        new CarroDb().getConverter()
      ),
    });
  }
}

interface ICheckin {
  ref: DocumentReference<Checkin>;
  localizacao: GeoPoint;
  dataHora: Date;
  usuario: DocumentReference<Passageiro>;
  carro: DocumentReference<Carro>;
}
