import { DocumentReference, DocumentSnapshot } from "firebase/firestore";
import ResidencialDb from "../db/residencialDb";

export default class Residencial implements IResidencial {
  public ref!: DocumentReference<Residencial>;
  public nome!: string;

  constructor(param: IResidencial) {
    Object.assign(this, param);
  }

  static fromFirestore(snapshot: DocumentSnapshot): Residencial {
    return new Residencial({
      ref: snapshot.ref.withConverter(new ResidencialDb().getConverter()),
      nome: snapshot.get("nome"),
    });
  }
}

interface IResidencial {
  ref: DocumentReference<Residencial>;
  nome: string;
}
