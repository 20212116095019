import { Chip } from "@mui/material";
import { DIAS } from "../models/horario";

type Props = {
  dia: DIAS;
};
export default function ChipDia({ dia }: Props) {
  return <Chip label={textoDia(dia)} color={corDia(dia)} variant="filled" />;
}

function textoDia(dia: DIAS): string {
  switch (dia) {
    case DIAS.Util:
      return "Útil";
    case DIAS.Sabado:
      return "Sábado";
    case DIAS.DomingoEFeriado:
      return "Domingo ou feriado";

    default:
      return "";
  }
}

function corDia(dia: DIAS): "warning" | "info" | "success" | "default" {
  switch (dia) {
    case DIAS.Util:
      return "warning";
    case DIAS.Sabado:
      return "info";
    case DIAS.DomingoEFeriado:
      return "success";

    default:
      return "default";
  }
}
