import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  FormGroup,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { deleteDoc, onSnapshot, query, setDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { map } from "lodash";
import { useEffect, useState } from "react";
import avatarColorGenerator from "../util/avatarColorGenerator";
import { Add, Delete, Edit } from "@mui/icons-material";
import MotoristaDb from "../db/motoristaDb";
import Motorista from "../models/motorista";

export default function PaginaMotoristas() {
  const [motoristas, setMotoristas] = useState<Motorista[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    return onSnapshot(
      query(
        new MotoristaDb().collection({
          motoristaId: "",
        })
      ),
      (queryDocs) => {
        setMotoristas(map(queryDocs.docs, (doc) => doc.data()));
      }
    );
  }, []);

  return (
    <Container maxWidth="lg">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Motorista</TableCell>
              <TableCell>Matrícula</TableCell>
              <TableCell>Email</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {map(motoristas, (usuario) => {
              return <RowMotorista motorista={usuario} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Fab
        variant="extended"
        size="large"
        color="success"
        sx={{ position: "fixed", bottom: 32, right: 32 }}
        onClick={() => setOpen(true)}
      >
        <Add />
        Adicionar
      </Fab>
      <DialogMotorista open={open} onClose={() => setOpen(false)} />
    </Container>
  );
}

function RowMotorista({ motorista }: { motorista: Motorista }) {
  const [open, setOpen] = useState(false);

  return (
    <TableRow key={motorista.ref.id}>
      <TableCell>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Avatar
            sx={{ backgroundColor: avatarColorGenerator(motorista.ref.id) }}
          >
            {motorista.nome[0]}
          </Avatar>
          <Box mr={1} />
          {motorista.nome}
        </Box>
      </TableCell>
      <TableCell>{motorista.matricula}</TableCell>
      <TableCell>{motorista.email}</TableCell>
      <TableCell align="right">
        <DialogMotorista
          motorista={motorista}
          open={open}
          onClose={() => setOpen(false)}
        />
        <IconButton color="info" onClick={() => setOpen(true)}>
          <Edit />
        </IconButton>
        <IconButton
          color="error"
          disabled
          onClick={() => deleteDoc(motorista.ref)}
        >
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function DialogMotorista({
  open,
  onClose,
  motorista,
}: {
  open: boolean;
  onClose: () => void;
  motorista?: Motorista;
}) {
  const [nome, setNome] = useState(motorista?.nome ?? "");
  const [matricula, setMotorista] = useState(motorista?.matricula ?? "");

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <FormGroup>
          <Box display="flex" flexDirection="column">
            <TextField
              label="Nome"
              autoComplete="name"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
            <Box pb={1} />
            <TextField
              label="Matrícula"
              value={matricula}
              inputProps={{ pattern: "[0-9]{7}" }}
              onChange={(e) => setMotorista(e.target.value)}
            />
          </Box>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          size="medium"
          variant="text"
          color={"inherit"}
          onClick={onClose}
        >
          {"Cancelar"}
        </Button>
        {!!motorista ? (
          <Button
            size="medium"
            variant="contained"
            color={"success"}
            disabled={!!!nome}
            onClick={() =>
              setDoc(
                motorista.ref,
                new Motorista({
                  ...motorista,
                  nome,
                  matricula,
                })
              ).then(onClose)
            }
          >
            {"Salvar"}
          </Button>
        ) : (
          <Button
            size="medium"
            variant="contained"
            color={"success"}
            disabled={!!!nome || !!!matricula}
            onClick={() =>
              httpsCallable<
                {
                  nome: string;
                  matricula: string;
                },
                void
              >(
                getFunctions(),
                "usuario-criarMotorista"
              )({
                nome,
                matricula,
              }).then(onClose)
            }
          >
            {"Adicionar"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
