import { DocumentReference, DocumentSnapshot } from "firebase/firestore";
import ResidencialDb from "../db/residencialDb";
import PassageiroDb from "../db/passageiroDb";
import Residencial from "./residencial";
import Administrador from "./administrador";
import AdministradorDb from "../db/administradorDb";
import moment from "moment";

export enum StatusCredencial {
  EMITIDA = "EMITIDA",
  CANCELADA = "CANCELADA",

  NAO_EMITIDA = "NAO_EMITIDA",
  VENCIDA = "VENCIDA",
}

export default class Passageiro implements IPassageiro {
  public ref!: DocumentReference<Passageiro>;
  public nome!: string;
  public email!: string;
  public residencial!: DocumentReference<Residencial>;
  public quadra!: string;
  public lote!: string;
  public credencial!: ICredencial | null;
  public fotoDePerfil?: string;

  static fromFirestore(snapshot: DocumentSnapshot): Passageiro {
    return new Passageiro({
      ref: snapshot.ref.withConverter(new PassageiroDb().getConverter()),
      nome: snapshot.get("nome"),
      email: snapshot.get("email"),
      residencial: (
        snapshot.get("residencial") as DocumentReference
      ).withConverter(new ResidencialDb().getConverter()),
      quadra: snapshot.get("quadra"),
      lote: snapshot.get("lote"),
      credencial:
        snapshot.get("credencial") === null
          ? null
          : {
              status: snapshot.get("credencial.status"),
              validade: snapshot.get("credencial.validade")?.toDate(),
              emitidaEm: snapshot.get("credencial.emitidaEm")?.toDate(),
              emitidaPor: !!snapshot.get("credencial.emitidaPor")
                ? (
                    snapshot.get("credencial.emitidaPor") as DocumentReference
                  ).withConverter(new AdministradorDb().getConverter())
                : null,
            },
      fotoDePerfil: snapshot.get("fotoDePerfil"),
    });
  }

  constructor(param: IPassageiro) {
    Object.assign(this, param);
  }
}

interface IPassageiro {
  ref: DocumentReference<Passageiro>;
  nome: string;
  email: string;
  residencial: DocumentReference<Residencial>;
  quadra: string;
  lote: string;
  credencial: ICredencial | null;
  fotoDePerfil?: string;
}

interface ICredencial {
  status: StatusCredencial;
  validade?: Date | null;
  emitidaEm?: Date | null;
  emitidaPor?: DocumentReference<Administrador> | null;
}

export function statusCredencial(
  credencial: ICredencial | null
): StatusCredencial {
  if (!!!credencial) return StatusCredencial.NAO_EMITIDA;
  else {
    switch (credencial.status) {
      case StatusCredencial.EMITIDA:
        if (moment(credencial.validade).isSameOrAfter(moment())) {
          return StatusCredencial.EMITIDA;
        } else {
          return StatusCredencial.VENCIDA;
        }
      case StatusCredencial.CANCELADA:
        return StatusCredencial.CANCELADA;
      default:
        return StatusCredencial.NAO_EMITIDA;
    }
  }
}
