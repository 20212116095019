import {
  DocumentReference,
  DocumentSnapshot,
  GeoPoint,
  Timestamp,
} from "firebase/firestore";
import LocalizacaoDb from "../db/localizacaoDb";

export default class Localizacao implements ILocalizacao {
  public ref!: DocumentReference<Localizacao>;
  public posicao!: GeoPoint;
  public dataHora!: Date;

  static fromFirestore(snapshot: DocumentSnapshot): Localizacao {
    return new Localizacao({
      ref: snapshot.ref.withConverter(new LocalizacaoDb().getConverter()),
      posicao: snapshot.get("posicao") as GeoPoint,
      dataHora: (snapshot.get("dataHora") as Timestamp).toDate(),
    });
  }

  constructor(param: ILocalizacao) {
    Object.assign(this, param);
  }
}

interface ILocalizacao {
  ref: DocumentReference<Localizacao>;
  posicao: GeoPoint;
  dataHora: Date;
}
